export const createMarkup = (text = '') => {
  return { __html: text }
}

export const formattedMessage = (message = '', className = '', style = {}) => {
  const text = message.replaceAll('\n', '<br>')
  return <div className={className} style={style} dangerouslySetInnerHTML={createMarkup(text)} />
}

export const normalizeName = (text = '') => {
  return text.replaceAll(' ', '_').replaceAll(/[()]/g, '')
}

export const replaceSpaces = (text = '') => {
  return text.replaceAll(' ', '_')
}
