import React from 'react';
import { Grid } from '@material-ui/core';
import { formattedMessage } from '../../../utils/textHandlerUtils';

export const TechnicalCard = ({ product = {} }) => {
  const {
    name = '',
    artno = '',
    technical_paragraph = [],
    technical_data = []
  } = product
  return (
    <div className='product-details-card'>
      <div className='title-box'>
        {formattedMessage(name.toUpperCase(), 'title')}
      </div>
      <div className='art-nr'>
        {artno}
      </div>
      <div className='paragraph-box'>
        {!!technical_paragraph.length && technical_paragraph.map(({ name, value }, index) => (
          <div className='paragraph' key={`${name}_${index}`}>
            <b className='name small-bold'>{name.toUpperCase()}</b>
            <div className='value'>
              {value}
            </div>
          </div>
        ))}
      </div>
      <div className='info-table'>
        {!!technical_data.length && technical_data.map(({ name, value }, index) => (
          <Grid container key={`${name}_${value}`} className={`row row-${index % 2}`}>
            <Grid xs={6} item className='name'>{name.toUpperCase()}</Grid>
            <Grid xs={6} item className='value'>{value.toUpperCase()}</Grid>
          </Grid>
        ))}
      </div>
    </div >
  )
}