export const getFlagImage = fileName => {
  try {
    return `url(${require(`../assets/images/flags/${fileName}.png`).default})` || ''
  } catch (error) {
    console.log('cannot find flag image file with name:', fileName)
  }
}

export const getLogoUrl = fileName => {
  try {
    return `url(${require(`../assets/images/logos/${fileName}.svg`).default})` || ''
  } catch (error) {
    console.log('cannot find logo file with name:', fileName)
  }
}

export const getUrlFromArray = (pictures = [], isHq = false) => {
  const [firstPicture] = pictures
  let url = ''
  if (isHq && firstPicture && firstPicture.url) {
    url = firstPicture.url
  }
  else if (firstPicture && firstPicture.formats && firstPicture.formats.small && firstPicture.formats.small.url) {
    url = firstPicture.formats.small.url
  }
  else if (firstPicture && firstPicture.formats && firstPicture.formats.thumbnail && firstPicture.formats.thumbnail.url) {
    url = firstPicture.formats.thumbnail.url
  }
  else if (firstPicture && firstPicture.url) {
    url = firstPicture.url
  }
  return makeImageUrl(url)
}

export const getUrlFromObject = (formats = {}) => {
  let url = ''
  if (formats && formats.small && formats.small.url) {
    url = formats.small.url
  }
  else if (formats && formats.thumbnail && formats.thumbnail.url) {
    url = formats.thumbnail.url
  }
  return makeImageUrl(url)
}

const makeBackendUrl = (url = '') => {
  const baseUrl = process.env.REACT_APP_DATA_URL || ''
  return url ? `${baseUrl}${url}` : ''
}

export const makeImageUrl = (url = '') => {
  return url ? `url(${makeBackendUrl(url)})` : ''
}

export const createEmptyClass = (url = '') => {
  return url ? '' : 'empty-image'
}
