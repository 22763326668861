const SLIDER_LENGTH = 4
const PORTRAIT_LENGTH = 1
const SLIDER_ROWS_ALL = 2
const SLIDER_LENGTH_ALL = 5

const getSlideLength = (items = [], itemLength = 0) => {
  return items.length > itemLength ? itemLength : items.length
}

export const makeAlbumSettings = () => {
  return {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
}

export const makeMainSettings = (items = [], isPortrait = false) => {
  const length = isPortrait ? PORTRAIT_LENGTH : SLIDER_LENGTH
  return {
    dots: false,
    infinite: items.length > length,
    speed: 500,
    slidesToShow: length,
    slidesToScroll: length,
  }
}

export const makeAllSettings = (items = [], isPortrait = false) => {
  const infiniteLength =
    isPortrait ?
      (PORTRAIT_LENGTH * SLIDER_ROWS_ALL) :
      (SLIDER_LENGTH_ALL * SLIDER_ROWS_ALL)
  const length = isPortrait ? PORTRAIT_LENGTH : SLIDER_LENGTH_ALL
  return {
    dots: true,
    arrows: false,
    infinite: items.length > infiniteLength,
    speed: 500,
    slidesToShow: getSlideLength(items, length),
    slidesToScroll: getSlideLength(items, length),
    rows: SLIDER_ROWS_ALL,
    slidesPerRow: 1
  }
}

export const getValidatedItems = (array = []) => {
  return array && !!array.length && array.filter(({ validated }) => validated)
}
