import { normalizeName, replaceSpaces } from "./textHandlerUtils"

export const sortByOrder = (array = []) => {
  return array.sort((a, b) => {
    const { order: orderA = '' } = a
    const { order: orderB = '' } = b
    return orderA - orderB
  })
}

export const getTypes = (array = [], type = '') => {
  const { types = [] } = (!!array.length && array.find(({ name }) => name === type)) || {}
  return types
}

export const sortBySelectOrder = (array = []) => {
  return (array && !!array.length && array.sort((a, b) => {
    const { select_order: orderA = 999 } = a || {}
    const { select_order: orderB = 999 } = b || {}
    return orderA - orderB
  })) || []
}

export const checkProductAttributes = (products = [], selections = {}) => {
  return products.filter(({ attributions = {} }) => {
    return !!Object.keys(attributions).filter(attr => {
      const { types = [] } = attributions[attr] || {}
      const selectionValue = selections[replaceSpaces(attr)] || ''
      if (typeof selectionValue === 'string') {
        return types.indexOf(selectionValue.toLowerCase()) > -1
      }
      else if (typeof selectionValue === 'object') {
        const normTypes = types.map(type => normalizeName(type)) || []
        return !!Object.keys(selectionValue).filter(selectionKey => {
          return selectionValue[selectionKey] && normTypes.indexOf(selectionKey) > -1
        }).length
      }
      else return false
    }).length
  })
}
