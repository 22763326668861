import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProvider } from './storage/context';
import { reducer } from './storage/reducers/reducer';
import { ProtectedRoute } from './components/Common/Routing';
import StorageHandler from './storage/StorageHandler';
import { IntroPage, MainPage, MainRangePage, RangePage, CategoryPage, ProductPage } from './components/Pages/ProductPages';
import { AboutPage, AboutPages } from './components/Pages/AboutPages';
import { GalleryPage, SolutionPage } from './components/Pages/OtherPages';
import { Internalization } from './components/Common/Internalization';
import { HomeButtons, BackButton/*, LogoutButton*/ } from './components/Common/Buttons';
import { storageScheme } from './utils/schemes';
import { PATHS } from './utils/constants';

const App = () => {
  const {
    root,
    menu,
    about,
    product,
    gallery,
    solution,
    parameters: {
      rangeId,
      categoryId,
      productId,
      pageId
    }
  } = PATHS

  return (
    <ContextProvider
      initialState={{ storage: storageScheme }}
      reducer={reducer}
    >
      <Internalization>
        <Router>
          <HomeButtons />
          {/*<LogoutButton />*/}
          <BackButton />
          <Switch>
            <ProtectedRoute path={`${menu}${gallery}`} component={GalleryPage} />
            <ProtectedRoute path={`${menu}${about}${pageId}`} component={AboutPages} />
            <ProtectedRoute path={`${menu}${about}`} component={AboutPage} />
            <ProtectedRoute path={`${menu}${solution}`} component={SolutionPage} />
            <ProtectedRoute path={`${menu}${product}${rangeId}${categoryId}${productId}`} component={ProductPage} />
            <ProtectedRoute path={`${menu}${product}${rangeId}${categoryId}`} component={CategoryPage} />
            <ProtectedRoute path={`${menu}${product}${rangeId}`} component={RangePage} />
            <ProtectedRoute path={`${menu}${product}`} component={MainRangePage} />
            <ProtectedRoute path={menu} component={MainPage} />
            <Route path={root} component={IntroPage} />
          </Switch>
        </Router>
        <StorageHandler />
      </Internalization>
    </ContextProvider>
  );
}

export default App;
