import React from 'react';
import { Grid } from '@material-ui/core';
import { getLogoUrl } from '../../../utils/imageHandler';
import { formattedMessage, normalizeName } from '../../../utils/textHandlerUtils';
import { filterTranslation } from '../../../utils/translationUtils';

export const ProductDetailsCard = ({ product = {}, currencies = [] }) => {
  const {
    name = '',
    artno = '',
    attributions = {},
    price = 0,
    description = {},
    data = [],
    bullet = []
  } = product

  const { water } = attributions || {}
  const { dont_show = false, types = [] } = water || {}
  const { eurChange = 0, usdChange = 0 } = currencies
  return (
    <div className='product-details-card'>
      <div className='title-box'>
        <div className='title' >
          {formattedMessage(name.toUpperCase())}
        </div>
      </div>
      <div className='art-nr'>
        {artno}
      </div>
      <div className='water-type-box'>
        {!dont_show && !!types.length && types.map((type, index) => {
          const typeName = normalizeName(type)
          return (
            <div className='water-type-logo logo-props' key={`${type}_${index}`} style={{ backgroundImage: getLogoUrl(typeName) }} />
          )
        })}
      </div>
      <div className='price-box'>
        <div className='price'>
          DKK {price}.-
        </div>
        <div className='price'>
          EURO {price * eurChange}.-
        </div>
        <div className='price'>
          USD {price * usdChange}.-
        </div>
      </div>
      <div className='title-box'>
        <div className='description'>
          {filterTranslation(description)}
        </div>
      </div>
      <div className='info-table'>
        {!!data.length && data.map(({ name, value }, index) => (
          <Grid container key={`${name}_${value}`} className={`row row-${index % 2}`}>
            <Grid xs={6} item className='name'>{name.toUpperCase()}</Grid>
            <Grid xs={6} item className='value'>{value}</Grid>
          </Grid>
        ))}
      </div>
      <div className='bullets-list'>
        {!!bullet.length && bullet.map(({ value }, index) => (
          <div key={index} className='bullet'>
            {`• ${value}`}
          </div>
        ))}
      </div>
    </div>
  )
}