import React from 'react';
import { BackgroundArray } from '../../Common/CardArrays/BackgroundArray';
import { PositionedTextBox } from '../../Common/TextBoxes';

export const KeepPage = ({ menuObject = {}, menuButtons }) => {
  const { bgArray, menuData } = menuObject
  return (
    <>
      <PositionedTextBox textData={menuData} type='about' />
      {menuButtons}
      <BackgroundArray backgroundArray={bgArray} />
    </>
  )
}
