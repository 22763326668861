import moment from 'moment';

const getFormattedDate = () => {
    const now = new Date()
    return moment(now).format('YYYY-MM-DD')
}

export const compareDates = (new_until = '') => {
    const todayDate = getFormattedDate()
    return moment(new_until).isAfter(todayDate) || false
}
