import React, { useContext } from 'react';
import { ZegoContext } from '../../../storage/context';
import { MenuCardArray } from '../../Common/CardArrays';
import { PositionedTextBox } from '../../Common/TextBoxes';
import { getMenuData } from '../../../utils/menuHelpers';

export const MainPage = () => {
  const [{ storage: { menus = [], mainmenus = [] } }] = useContext(ZegoContext)
  const { menuData, bgUrl } = getMenuData(menus, 'main_page')
  return (
    <div
      style={{ backgroundImage: bgUrl }}
      className='main-page bg-props'
    >
      <PositionedTextBox textData={menuData} textColor={'white'} type='main' />
      <MenuCardArray menus={mainmenus} />
    </div>
  )
}
