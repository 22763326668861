import React, { useContext, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ZegoContext } from '../../../storage/context';
import { FlatButton } from './';
import { BACK } from '../../../utils/constants';

export const BackButton = () => {
  const [{ mediaView = false }, dispatch] = useContext(ZegoContext)
  const history = useHistory()
  const { url } = useRouteMatch()
  const path = (history && history.location && history.location.pathname) || ''

  useEffect(() => { }, [url])

  const navigateBack = () => {
    return mediaView ?
      dispatch({
        type: 'setView',
        mediaView: false
      }) :
      history.goBack()
  }

  return (
    path !== '/' && path !== '/menu' &&
    <FlatButton name={BACK} className='back-button' handleClick={navigateBack} />
  )
}
