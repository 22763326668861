import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { ZegoContext } from '../../../storage/context';
import { PdfPage } from '../../Common/Pdf';
import { FlatButton } from '../../Common/Buttons';
import { ProductCard } from '../../Common/Cards';
import { checkProductAttributes, getTypes, sortBySelectOrder } from '../../../utils/arrayHandlers';
import { getLogoUrl } from '../../../utils/imageHandler';
import { getMenuData } from '../../../utils/menuHelpers';
import { normalizeName, replaceSpaces } from '../../../utils/textHandlerUtils';
import { getCurrencies } from '../../../utils/productHelpers';
import {
  DEFAULT_SELECT,
  WORKTOP_SHOW_ARGUMENTS,
  WATER,
  CONTROL,
  WORKTOP,
  CHANGE,
  RANGES_NOT_TO_SHOW
} from '../../../utils/constants';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const SolutionPage = () => {
  const [{ storage: {
    menus = [],
    ranges = [],
    categories = [],
    currencies = [],
    'pdf-exports': pdfData = [],
    'product-attributes': attributions = []
  } }] = useContext(ZegoContext)
  const [selections, setSelections] = useState({})
  const [selectedToPrint, setSelectedToPrint] = useState([])
  const [view, setView] = useState('main')
  const [showPdf, setShowPdf] = useState(false)
  const history = useHistory()
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const { bgUrl } = getMenuData(menus, 'solution')

  useEffect(() => {
    if (selections && attributions && !!attributions.length && !Object.keys(selections).length) {
      const selectionObject = {}
      !!attributions.length && attributions.forEach(({ name }) => {
        const selectionValue = name === WATER ? {} : '-'
        selectionObject[replaceSpaces(name)] = selectionValue
      })
      setSelections(selectionObject)
    }
  }, [selections, attributions])

  const handleWaterChange = (name = '') => {
    if (selections && selections[WATER]) {
      setSelections(prevState => ({
        ...prevState,
        [WATER]: {
          ...prevState[WATER],
          [name]: !prevState[WATER][name]
        }
      }))
    }
  }

  const handleSelectorChange = (type = '', event) => {
    if (selections && selections[type]) {
      const { value = '' } = event.target
      setSelections(prevState => ({
        ...prevState,
        [type]: value
      }))
    }
  }

  const handleControlChange = (value = '') => {
    if (selections && selections[CONTROL]) {
      setSelections(prevState => ({
        ...prevState,
        [CONTROL]: value
      }))
    }
  }

  const handleGoToDetails = () => {
    const selectedFields = !!ranges.length && ranges.map((range = {}) => {
      const { name = '', products = [] } = range
      const foundProducts = checkProductAttributes(products, selections)
      if (RANGES_NOT_TO_SHOW.indexOf(name) === -1 && !!foundProducts.length) {
        return {
          name,
          products: foundProducts,
          use: 0
        }
      }
      return null
    })
    const filteredSelected = selectedFields.filter(Boolean)
    setSelectedToPrint(filteredSelected)
    return setView('details')
  }

  const handlePrintSelection = (index = 0, event) => {
    const { selectedIndex = 0 } = event.target
    const selectedField = [...selectedToPrint]
    selectedField[index].use = selectedIndex - 1
    setSelectedToPrint(selectedField)
  }

  const handlePdfShow = () => {
    if (!!selectedToPrint.length)
      setShowPdf(true)
  }

  const handleProductClick = (id, range, category) => {
    const { name: rangeName = '' } = ranges.find(({ id }) => id === range)
    const { name: categoryName = '' } = categories.find(({ id }) => id === category)
    history.push(`product/${rangeName}/${categoryName}/${id}`)
  }

  const worktopAttrs =
    (!!attributions.length &&
      attributions.find(({ name }) => name === WORKTOP)) ||
    { name: '', types: [] }

  const waterTypes = getTypes(attributions, WATER)
  const controlTypes = getTypes(attributions, CONTROL)

  const selectAttributions =
    sortBySelectOrder(attributions).filter(({ select_field }) => select_field)

  const currencyObject = getCurrencies(currencies)
  return (
    <div
      className={`solution-page${makePortraitClass(isPortrait)}`}
      style={{ backgroundImage: bgUrl }}
    >
      <div className='text-box'>
        COMBINE YOUR SOLUTION
      </div>
      {showPdf &&
        <PdfPage
          toPrint={selectedToPrint}
          pdfData={pdfData[0] || {}}
          currencies={currencyObject}
          closePdf={() => setShowPdf(false)}
        />}
      {view === 'main' ?
        <>
          <div className='water-selection-box'>
            {!!waterTypes.length && waterTypes.map(({ name = '', value = '' }, index) => {
              const waterName = normalizeName(name)
              const checked =
                (selections && selections[WATER] && selections[WATER][waterName]) || false
              return (
                <div
                  key={`${waterName}_${index}`}
                  className='selector'
                  onClick={() => handleWaterChange(waterName)}
                >
                  <div
                    className='icon logo-props'
                    style={{ backgroundImage: getLogoUrl(waterName) }}
                  />
                  <b className='small-bold label'>{value}</b>
                  <div>
                    <input
                      type="checkbox"
                      className='checkbox'
                      checked={checked}
                      onChange={() => { }} />
                  </div>
                </div>
              )
            })}
          </div>
          <div className='multi-selection-box'>
            {!!controlTypes.length && controlTypes.map((type = {}, index) => {
              const { name = '' } = type
              const checked =
                (selections && replaceSpaces(selections[CONTROL])) === replaceSpaces(name) || false
              return (
                <div
                  className='selector control'
                  key={`${name}_${index}`}
                  onClick={() => handleControlChange(replaceSpaces(name))}
                >
                  {isPortrait ?
                    <>
                      <input
                        id={name}
                        type="radio"
                        name="control"
                        checked={checked}
                        value={replaceSpaces(name)}
                        onChange={() => { }}
                      />
                      <label><b className='small-bold'>{name.toUpperCase()}</b></label>
                    </> :
                    <>
                    <label><b className='small-bold'>{name.toUpperCase()}</b></label>
                      <input
                        id={name}
                        type="radio"
                        name="control"
                        checked={checked}
                        value={replaceSpaces(name)}
                        onChange={() => { }}
                      />
                    </>
                  }
                </div>
              )
            })}
          </div>
          <div className='multi-selection-box options'>
            {selectAttributions &&
              !!selectAttributions.length &&
              selectAttributions.map((attr = {}, index) => {
                const { name = '', show_name = '', types = [] } = attr
                const value = (selections && selections[replaceSpaces(name)]) || DEFAULT_SELECT
                return (
                  <div className='selector' key={`${name}_${index}`}>
                    <b className='small-bold label'>{show_name.toUpperCase() || name.toUpperCase()}</b>
                    <div>
                      <select
                        className='select-field'
                        value={value}
                        onChange={event => handleSelectorChange(replaceSpaces(name), event)}
                      >
                        <option>{DEFAULT_SELECT}</option>
                        {!!types.length && types.map(({ name = '' }) => (
                          <option name={name} key={name}>
                            {name.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )
              })}
          </div>
          {selections &&
            selections.unit &&
            WORKTOP_SHOW_ARGUMENTS.indexOf(selections.unit) > -1 &&
            (<div className='multi-selection-box options'>
              <div className='selector worktop'>
                <b className='small-bold label'>{worktopAttrs.name.toUpperCase()}</b>
                <div>
                  <select
                    className='select-field'
                    value={(selections && selections[WORKTOP]) || DEFAULT_SELECT}
                    onChange={event => handleSelectorChange(WORKTOP, event)}
                  >
                    <option>{DEFAULT_SELECT}</option>
                    {!!worktopAttrs.types.length && worktopAttrs.types.map(({ name = '' }) => (
                      <option name={name} key={name}>
                        {name.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>)}
          <div className='button-container'>
            <FlatButton
              name='next'
              className='flat-button next'
              handleClick={handleGoToDetails}
            />
          </div>
        </> :
        <>
          <div className='card-container'>
            {selectedToPrint &&
              !!selectedToPrint.length &&
              selectedToPrint.map(({ name, products, use }, index) => {
                const product = products[use]
                const listLength = products.length
                const lengthy = listLength > 1
                const options = lengthy ? `(${listLength} OPTIONS)` : ''
                const className = lengthy ? ' lengthy' : ''
                return (
                  <div key={`${name}_${index}`} className={`selector${className}`}>
                    <b className='title small-bold'>{name.toUpperCase()}</b>
                    <div className={!lengthy ? 'empty' : ''}>{options}</div>
                    <ProductCard item={product} lengthy={lengthy} handleClick={handleProductClick} />
                    {lengthy &&
                      <select
                        className='select-field'
                        onChange={event => handlePrintSelection(index, event)}
                      >
                        <option>{CHANGE}</option>
                        {!!products.length && products.map(({ name: productName = '' }) => (
                          <option name={productName} key={productName}>
                            {productName.toUpperCase()}
                          </option>
                        ))}
                      </select>}
                  </div>
                )
              })}
          </div>
          <div className='button-container'>
            <FlatButton
              name='edit selection'
              className='flat-button back'
              handleClick={() => setView('main')}
            />
            <FlatButton
              name='share pdf'
              className='flat-button share'
              handleClick={handlePdfShow}
            />
          </div>
        </>
      }
    </div>
  )
}
