import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ZegoContext } from "../../../storage/context"
import { LANG } from '../../../utils/constants';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';
import { getTranslatedMessage } from '../../../utils/translationUtils';

export const FlatButton = ({ name = '', className = '', index = 0, style = {}, handleClick = () => { } }) => {
	const [text, setText] = useState('')
	const [{ storage: { translations = [] } }] = useContext(ZegoContext)
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
	const selectedLanguage = localStorage.getItem(LANG) || 'en'

	useEffect(() => {
		const translatedText = getTranslatedMessage(translations, name)
		setText(translatedText)
	}, [selectedLanguage, name, translations])

	return (
		<button
			className={`flat-button ${className} pointer${makePortraitClass(isPortrait)}`}
			style={style}
			onClick={() => handleClick({ name, index })}
		>
			{text.toUpperCase()}
		</button>
	)
}
