import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { toCanvas } from 'html-to-image';
import jsPDF from 'jspdf';
import { FlatButton } from '../Buttons';
import refresh from '../../../assets/images/logos/refresh.svg';
import { SearchField } from '../Fields';

const CANVAS_WIDTH = 105
const CANVAS_HEIGHT = 150

export const PdfSenderDialog = ({ closeDialog = () => { } }) => {
  const [to, setTo] = useState('')
  const [from, setFrom] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const canvasStyle = isPortrait ?
    {} :
    { width: `${CANVAS_WIDTH}px`, height: `${CANVAS_HEIGHT}px` }

  const createCanvas = async (canvasDim = {}, imgDim = {}) => {
    const input = document.getElementById('pdf-page')
    return await toCanvas(input, canvasDim, imgDim)
  }

  const sendPdf = async () => {
    setSending(true)
    try {
      const url = process.env.REACT_APP_DATA_URL
      const inputs = document.getElementsByClassName('pdf-page')
      const inputsArray = Array.from(inputs)
      const pdf = new jsPDF()
      const pdfGenerating = new Promise((resolve, reject) => {
        inputsArray.forEach(async (input, index) => {
          if (index > 0) pdf.addPage()
          try {
            const canvas = await toCanvas(input)
            const img = canvas.toDataURL('image/png')
            pdf.setPage(index + 1)
            pdf.addImage(img, 'JPEG', 0, 0)
            if (index === inputsArray.length - 1) resolve()
          } catch (error) {
            console.log('error', error)
            setSending(false)
            reject(error)
          }
        })
      })
      pdfGenerating.then(() => {
        const blob = pdf.output('blob')
        const data = { to, from, subject, message }

        const formData = new FormData()
        formData.append('files.pdf', blob, 'doc.pdf')
        formData.append('data', JSON.stringify(data))

        const request = new XMLHttpRequest()

        request.onreadystatechange = async () => {
          if (request.readyState === 4 && request.status === 200) {
            const { responseText = '' } = request
            const { id: docId = '' } = JSON.parse(responseText)
            pdf.close()
            await axios.get(`${url}/pdfs-to-sends/send/${docId}`, {
              headers: {
                accept: 'application/json'
              }
            })
            setSending(false)
            closeDialog()
          }
        }
        request.open('POST', `${url}/pdfs-to-sends`)
        request.send(formData)
      })
    } catch (error) {
      setSending(false)
      console.log('error')
    }
  }

  useEffect(() => {
    const showCanvas = async () => {
      const canvasDimensions = { canvasWidth: CANVAS_WIDTH, canvasHeight: CANVAS_HEIGHT }
      const imgDimensions = { width: CANVAS_WIDTH, height: CANVAS_HEIGHT }
      const canvas = await createCanvas(canvasDimensions, imgDimensions)
      const imageHolder = document.getElementById('image-container')
      if (imageHolder.innerHTML.length === 0)
        imageHolder.appendChild(canvas);
    }
    return isPortrait ? null : showCanvas()
  }, [isPortrait])

  const handleInputChange = (event, type = '') => {
    const { value = '' } = event.target

    switch (type) {
      case 'to':
        setTo(value)
        break
      case 'from':
        setFrom(value)
        break
      case 'subject':
        setSubject(value)
        break
      case 'message':
        setMessage(value)
        break
      default:
        break
    }
  }
  return (
    <div className='sender-dialog'>
      <div className='image-container-box'>
        <div
          id='image-container'
          className='image-container'
          style={canvasStyle}
        />
        {sending && <div className='logo-container'>
          <img
            alt='refresh'
            src={refresh}
            className={`sending-logo logo-props sending`}
          />
        </div>}
        <SearchField
          placeholder='TO:'
          value={to}
          className='email'
          handleChange={event => handleInputChange(event, 'to')}
        />
        {/*<SearchField
          placeholder='FROM:'
          value={from}
          className='email'
          handleChange={event => handleInputChange(event, 'from')}
        />*/}
        <SearchField
          placeholder='SUBJECT:'
          value={subject}
          className='subject'
          handleChange={event => handleInputChange(event, 'subject')}
        />
        <SearchField
          placeholder='MESSAGE:'
          value={message}
          className='message'
          handleChange={event => handleInputChange(event, 'message')}
          long
        />
        <FlatButton
          name='send'
          className='flat-button send'
          handleClick={sendPdf}
        />
      </div>
    </div>
  )
}