const setStorage = (state, { storage }) => {
  if (storage) {
    state.storage = storage
    state.isSetted = true
  }
  return { ...state }
}

const setView = (state, { mediaView }) => {
  state.mediaView = mediaView
  return { ...state }
}

const setError = (state, { error }) => {
  if (error && error.text) {
    state.error = error
  }
  return { ...state }
}

const nullError = (state) => {
  state.error = { text: '', code: 0 }
  return { ...state }
}

const nullToken = (state, { token }) => {
  state.token = ''
  nullError(state, {})
  return { ...state }
}

const setToken = (state, { token }) => {
  if (token) {
    state.token = token
  }
  return { ...state }
}

const setGetData = (state, { getData }) => {
  state.getData = getData
  return { ...state }
}

const actions = {
  setStorage,
  setView,
  setError,
  nullError,
  nullToken,
  setToken,
  setGetData
};

export const reducer = (state, action) => {
  if (action && action.type && actions[action.type]) {
    return actions[action.type](state, action);
  }
  return { ...state };
};
