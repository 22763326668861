import React, { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ZegoContext } from '../../../storage/context';
import { FlatButtonArray } from '../../Common/ButtonArrays';
import { SearchTextBox } from '../../Common/TextBoxes';
import { CardListArray } from '../../Common/CardArrays';
import { getMenuData } from '../../../utils/menuHelpers';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const MainRangePage = () => {
  const [{ storage: { ranges = [], menus = [], products = [] }, mediaView = false }, dispatch] = useContext(ZegoContext)
  const [searchText, setSearchText] = useState('')
  const { url } = useRouteMatch()
  const history = useHistory()
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const { menuData, bgUrl } = getMenuData(menus, 'main_category_page')

  const selectedProducts =
    products &&
    !!products.length &&
    products.filter(({ artno = '', name = '', keywords = [] }) => {
      const art = artno.toLowerCase()
      const naming = name.toLowerCase()
      return (
        art.indexOf(searchText) > -1 ||
        naming.indexOf(searchText) > -1 ||
        !!(!!keywords.length && keywords.filter(({ value = '' }) => {
          return value.toLowerCase().indexOf(searchText) > -1
        }
        )).length
      )
    })

  const handleMenuClick = ({ name = '' }) => {
    history.push(`${url}/${name}`)
  }

  const handleInputChange = (event) => {
    const { value = '' } = event.target
    setSearchText(value)
  }

  const handleKeyDown = (event) => {
    const { keyCode } = event
    if (keyCode === 13) {
      dispatch({
        type: 'setView',
        mediaView: true
      })
    }
  }

  const handleProductClick = (productId = '') => {
    const actualProduct =
      (products &&
        !!products.length &&
        products.find(({ id }) => id === productId)) || {}
    const { range = {}, category = {} } = actualProduct
    const { name: rangeName = '' } = range
    const { name: categoryName = '' } = category
    history.push(`${url}/${rangeName}/${categoryName}/${productId}`)
  }

  return (
    <div
      style={{ backgroundImage: bgUrl }}
      className={`main-category-page bg-props${makePortraitClass(isPortrait)}`}
    >
      {!mediaView ?
        <>
          <SearchTextBox
            textData={menuData}
            type='main'
            value={searchText}
            handleChange={handleInputChange}
            handleKeyDown={handleKeyDown}
          />
          <FlatButtonArray menus={ranges} handleClick={handleMenuClick} />
        </> :
        <CardListArray
          listArray={selectedProducts}
          all
          handleClick={handleProductClick}
        />
      }
    </div>
  )
}
