import React, { useEffect, useState } from 'react';
import play from '../../../assets/images/logos/play.png';
const VIDEO_ELEM = 'video-player'

const VideoPlayer = ({ src = '', index = 0, isFocused }) => {
  const [isMediaPaused, setIsMediaPaused] = useState(true)
  const svgStyle = {
    backgroundImage: `url(${play})`,
    width: '100px',
    height: '100px',
    position: 'absolute',
    left: 'calc(50% - 50px)',
    top: 'calc(50% - 50px)',
  }

  useEffect(() => {
    const player = document.getElementById(`${VIDEO_ELEM}_${index}`)
    isMediaPaused ? player.pause() : player.play()
    if(!isFocused && !isMediaPaused) {
      player.pause()
    }
  }, [isMediaPaused, isFocused, index])

  const handleVideoClick = () => {
    setIsMediaPaused(prevState => !prevState)
  }

  return (
    <div className='video-box pointer'>
      {isMediaPaused &&
        <div className='play-icon logo-props' style={svgStyle} onClick={handleVideoClick} />}
      <video
        autoPlay={false}
        onClick={handleVideoClick}
        className={VIDEO_ELEM}
        id={`${VIDEO_ELEM}_${index}`}>
        <source src={src} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </div >
  )
}

export default VideoPlayer
