import React, { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ZegoContext } from '../../../storage/context';
import { FlatButtonArray } from '../../Common/ButtonArrays/FlatButtonArray';
import { PositionedTextBox } from '../../Common/TextBoxes';
import { getMenuData } from '../../../utils/menuHelpers';
import { ABOUT_MENUS } from '../../../utils/constants';

export const AboutPage = () => {
  const [{ storage: { menus = [] } }] = useContext(ZegoContext)
  const history = useHistory()
  const { url } = useRouteMatch()
  const { menuData, bgUrl } = getMenuData(menus, 'about')

  const handleMenuClick = ({ name = '' }) => {
    history.push(`${url}/${name}`)
  }
  return (
    <div
      style={{ backgroundImage: bgUrl }}
      className='about-page bg-props'
    >
      <PositionedTextBox textData={menuData} type='about' />
      <FlatButtonArray menus={ABOUT_MENUS} handleClick={handleMenuClick} />
    </div>
  )
}
