import React, { useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { ZegoContext } from '../../../storage/context';
import { useEffect } from 'react';
import { LANG } from '../../../utils/constants';

export const Internalization = ({ children }) => {
  const [messages, setMessages] = useState({ key: 'dk' })
  const [{ storage: { intl = {} }, selected_language = localStorage.getItem(LANG) }] = useContext(ZegoContext)

  useEffect(() => {
    const messages = {}
    if (!!intl && !!Object.keys(intl).length && !!selected_language && !!intl[selected_language]) {
      intl[selected_language].forEach(item => {
        const key = Object.keys(item)
        messages[key] = item[key]
      })
      setMessages(messages)
    }
  }, [intl, selected_language])

  return (
    <IntlProvider locale={selected_language} messages={messages} onError={() => { }}>
      {children}
    </IntlProvider>
  )
}

