const PORTRAIT_RATIO = 0.5
const DESC_PORTRAIT_RATIO = 0.6

export const positionSelector = {
  top: {
    top: '15vh'
  },
  middle: {
    top: '50vh',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },
  bottom: {
    bottom: '10vh'
  }
}

const makeTitlePortrait = (size = 0, isPortrait = false) => {
  return isPortrait ? (PORTRAIT_RATIO * size) : size
}

const makeDescPortrait = (size = 0, isPortrait = false) => {
  return isPortrait ? (DESC_PORTRAIT_RATIO * size) : size
}

export const makeTitleStyle = (line = 0, font = 0, isPortrait = false) => {
  const lineHeight = makeTitlePortrait(line, isPortrait)
  const fontSize = makeTitlePortrait(font, isPortrait)
  return {
    lineHeight: `${lineHeight}px`,
    fontSize: `${fontSize}px`,
    marginBottom: `25px`
  }
}

export const makeDescStyle = (line = 0, font = 0, isPortrait = false) => {
  const lineHeight = makeDescPortrait(line, isPortrait)
  const fontSize = makeDescPortrait(font, isPortrait)
  return {
    lineHeight: `${lineHeight}px`,
    fontSize: `${fontSize}px`,
    marginBottom: `10px`
  }
}

export const makePortraitClass = (isPortrait = false) => {
  return isPortrait ? ' portrait' : ''
}
