import { SUPPORTED_VIDEO_FORMATS, SUPPORTED_IMAGE_FORMATS } from './constants';
const baseUrl = process.env.REACT_APP_DATA_URL

export const getFileExtension = (fileName = '') => fileName.split('.').pop()

export const getLink = (fileName = '') => isFileVideo(fileName)
  ? `${baseUrl}${fileName}` :
  `url(${baseUrl}${fileName})`

export const isFileVideo = (fileName = '') => {
  return SUPPORTED_VIDEO_FORMATS.includes(getFileExtension(fileName))
}

export const isFileSupported = (fileName = '') => {
  const SUPPORTED_FORMATS = [...SUPPORTED_VIDEO_FORMATS, ...SUPPORTED_IMAGE_FORMATS]
  return SUPPORTED_FORMATS.includes(getFileExtension(fileName))
}
