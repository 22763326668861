import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { ZegoContext } from '../../../storage/context';
import { LogoFlatButtonArray } from '../../Common/ButtonArrays';
import { LANG, LANGUAGES, MENU } from '../../../utils/constants';
import { getMenuData } from '../../../utils/menuHelpers';
import { formattedMessage } from '../../../utils/textHandlerUtils';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const IntroPage = () => {
  const [{ getData = false, storage: { menus = [] } }, dispatch] = useContext(ZegoContext)
  const { menuData, bgUrl } = getMenuData(menus, 'intro_page')
  const history = useHistory()
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const selectedLanguage = localStorage.getItem(LANG) || 'en'

  const { header = {}, description = {} } = menuData

  useEffect(() => {
    if (menus && !menus.length && !getData) {
      dispatch({
        type: 'setGetData',
        getData: true
      })
    }
  })

  const handleFlatButtonClick = (lang = '') => {
    if (selectedLanguage !== lang) {
      localStorage.setItem(LANG, lang)
    }
    history.push(MENU)
  }

  return (
    <div
      className={`intro-page bg-props${makePortraitClass(isPortrait)}`}
      style={{ backgroundImage: bgUrl }}
    >
      <b className='small-bold'>{formattedMessage(header[selectedLanguage], 'title')}</b>
      {formattedMessage(description[selectedLanguage], 'description')}
      <LogoFlatButtonArray array={LANGUAGES} handleClick={handleFlatButtonClick} />
      <div className='footer'>
        WWW.ZEGOTECH.COM
      </div>
    </div>
  )
}
