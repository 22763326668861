import React, { useContext, useState } from 'react';
import { ZegoContext } from '../../../storage/context';
import ReactCardFlip from 'react-card-flip';
import { Flip } from 'react-reveal';
import { getUrlFromArray } from '../../../utils/imageHandler';
import { formattedMessage } from '../../../utils/textHandlerUtils';
import { sortByOrder } from '../../../utils/arrayHandlers';
import { filterTranslation } from '../../../utils/translationUtils';

export const IconPage = ({ menuObject = {}, menuButtons }) => {
  const [{ storage: { icons = [] } }] = useContext(ZegoContext)
  const [flippedCircles, setFlippedCircles] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
  })
  const { bgArray, menuData: { header: { en = '' } = {} } = {} } = menuObject

  const handleCircleClick = (index = 0) => {
    setFlippedCircles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }))
  }

  const orderedIcons = sortByOrder(icons)

  return (
    <div className="icon-page bg-props" style={{ backgroundImage: getUrlFromArray(bgArray, true) }}>
      {menuButtons}
      <div className="circle-container">
        <div id="circle" className="middle">
          <div className='values-text'>
            {formattedMessage(en)}
          </div>
          {orderedIcons && !!orderedIcons.length && orderedIcons.map(({ description = {}, image = [] }, index) => {
            const isFlipped = flippedCircles[index]
            const flipClassName = isFlipped ? ' flipped' : ''
            return (
            <Flip
              left
              delay={index * 200}
              key={index}
            >
              <div className={`circle circle-${index}${flipClassName}`} >
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                  <div
                    className='circle-face logo-props pointer'
                    onClick={() => handleCircleClick(index)}
                    style={{ backgroundImage: getUrlFromArray(image) }} />

                  <div
                    className='circle-face back pointer'
                    onClick={() => handleCircleClick(index)}
                    style={{ backgroundColor: 'white' }}>
                    <div className='description'>
                      {filterTranslation(description)}
                    </div>
                  </div>
                </ReactCardFlip>
              </div>
            </Flip>
          )}
          )}
        </div>
      </div>
    </div>
  )
}
