import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ZegoContext } from '../../../storage/context';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';
import { BackgroundArray } from '../../Common/CardArrays';

export const GalleryPage = () => {
  const [{ storage: { galleries = [] } }] = useContext(ZegoContext)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const pages = (!!galleries.length && galleries.map(({ images = [], description = '' }) => {
    const [firstImage] = images
    const { url = '' } = firstImage
    return {
      url,
      description
    }
  })) || []

  return (
    <div className={`gallery-page${makePortraitClass(isPortrait)}`}>
      <BackgroundArray backgroundArray={pages} text />
    </div>
  )
}