import React from 'react';
import { Grid } from '@material-ui/core';
import { getUrlFromArray, createEmptyClass } from '../../../utils/imageHandler';
import { compareDates } from '../../../utils/dateHandlerUtils';
import { GetMessage } from '../../../utils/translationUtils';

export const ProductCard = ({ item = {}, color = '', lengthy = false, handleClick = () => { } }) => {
  const { artno = '', name = '', new_until = '', images = [], id = '', category = '', range = '' } = item
  const isProductNew = compareDates(new_until)
  const imageUrl = getUrlFromArray(images)

  const handleProductClick = () => {
    handleClick(id, range, category)
  }

  return (
    <Grid
      item
      className='card-item pointer'
      style={{ borderColor: color }}
      onClick={handleProductClick}
    >
      <div
        className={`card-image logo-props ${createEmptyClass(imageUrl)}`}
        style={{ backgroundImage: imageUrl }}
      />
      {isProductNew && <div className='new-label'>{GetMessage('details.text.new').toUpperCase() || ''}</div>}
      <div className='card-text-container'>
        <div className='card-text-box'>
          <b className='description_1 small-bold'>
            {artno.toUpperCase()}
          </b>
          <div className='description_2'>
            {name.toUpperCase()}
          </div>
        </div>
      </div>
      {lengthy &&
        <div className='next-icon'>
          {'>>'}
        </div>}
    </Grid>
  )
}

