import React from 'react';
import { Intl } from '../components/Common/Internalization';
import { createMarkup } from './textHandlerUtils';
import { INTL, LANG } from './constants';

export const GetMessage = (id = '') => {
  if (id) {
    const text = { id }
    const message = Intl.formatMessage(text)
    return message
  }
  else return id
}

const makeTextCapped = (message, capped) => {
  return capped ? message.toUpperCase() : message
}

export const getTranslatedMessage = (translations = [], name = '') => {
  const language = localStorage.getItem(LANG) || ''
  const translationObject = translations.find(({ key }) => {
    return key === `button.${name}` || key === `menu.${name}` || (key.indexOf('tooltip') > -1 && key === name)
  }) || {}
  return translationObject[language] || name
}

export const filterTranslation = (translations = {}) => {
  const language = localStorage.getItem(LANG) || ''
  return typeof translations === 'string' ? translations : translations[language] || ''
}

export const formattedMessage = (className = '', id = INTL, capped = true, forceInner = false) => {
  const message = GetMessage(id)
  const styledMessage = makeTextCapped(message, capped)
  return (
    message === id && !forceInner ?
      <div className={className}>{styledMessage}</div> :
      className ?
        <div className={className} dangerouslySetInnerHTML={createMarkup(styledMessage)} /> :
        <div dangerouslySetInnerHTML={createMarkup(styledMessage)} />
  )
}

