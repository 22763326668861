import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ZegoContext } from '../../../storage/context';
import { SelectableFlatButtonArray } from '../../Common/ButtonArrays';
import { CardListArray } from '../../Common/CardArrays';
import { PositionedTextBox } from '../../Common/TextBoxes';
import { getUrlFromArray } from '../../../utils/imageHandler';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const CategoryPage = () => {
  const [{ storage:
    { ranges = [], categories = [] }, mediaView = false },
    dispatch] = useContext(ZegoContext)
  const [selectedCategory, setSelectedCategory] = useState(0)
  const { rangeId, categoryId } = useParams()
  const history = useHistory()
  const { url } = useRouteMatch()
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const actualCategory = categories.find(({ name }) => name === categoryId) || {}
  const { products = [], background = [] } = actualCategory
  const actualRange = ranges.find(({ name }) => name === rangeId) || {}
  const { categories: actualCategories = {} } = actualRange
  const bgUrl = getUrlFromArray(background, true)

  useEffect(() => {
    const actualCategoryIndex = categories.findIndex(({ name }) => name === categoryId) || 0
    setSelectedCategory(actualCategoryIndex)
  }, [categories, categoryId])

  const handleMenuClick = ({ name = '' }) => {
    history.push(name)
  }

  const handleProductClick = (id = '') => {
    history.push(`${url}/${id}`)
  }

  const handleAllClick = () => {
    dispatch({
      type: 'setView',
      mediaView: true
    })
  }

  return (
    <div
      style={{ backgroundImage: bgUrl }}
      className={`category-page bg-props${makePortraitClass(isPortrait)}`}
    >
      {!mediaView ?
        <>
          <PositionedTextBox textData={actualCategory} type='main' buttonName='view all' handleClick={handleAllClick} />
          <SelectableFlatButtonArray menus={actualCategories} selected={selectedCategory} small handleClick={handleMenuClick} />
          <CardListArray listArray={products} handleClick={handleProductClick} />
        </> :
        <>
          <CardListArray listArray={products} handleClick={handleProductClick} all />
        </>

      }
    </div>
  )
}