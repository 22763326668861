import React from 'react';
import { getFlagImage } from '../../../utils/imageHandler';

export const LogoFlatButton = ({ name = '', handleClick = () => { } }) => (
  <button
    className='flat-button logo-flag pointer logo-props'
    onClick={handleClick}
    style={{ backgroundImage: getFlagImage(name) }}
  />
)
