import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FlatButton } from '../Buttons';
import { PdfSenderDialog } from '../Dialogs';
import { getLogoUrl, getUrlFromArray, getUrlFromObject } from '../../../utils/imageHandler';
import { formattedMessage, normalizeName } from '../../../utils/textHandlerUtils';
import { pdfStyle } from '../../../utils/pdfHelper';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const ProductPdfPage = ({ toPrint = {}, pdfData = {}, currencies = {}, closePdf = () => { } }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const {
    name = '',
    artno = '',
    attributions = {},
    price = 0,
    data = [],
    bullet = [],
    images = [],
    technical_data = []
  } = toPrint
  const { water } = attributions || {}
  const { dont_show = false, types = [] } = water || {}

  const { eurChange = 0, usdChange = 0 } = currencies
  const { header = '', footer = '', logo = [] } = pdfData
  return (
    <div className={`pdf-main-page${makePortraitClass(isPortrait)}`}>
      <div className='pdf-container'>
        {!isPortrait && <div className='exit-button pointer' onClick={closePdf}>X</div>}
        <div className='pdf-page' id='pdf-page' style={pdfStyle.page} >
          <div
            style={{
              backgroundImage: getUrlFromArray(logo),
              ...pdfStyle.logoProps,
              ...pdfStyle.logo
            }}
          />
          <div style={pdfStyle.headerBox}>
            <div style={pdfStyle.header}>
              {header}
            </div>
          </div>
          <div>
            <div style={pdfStyle.picContainer}>
              <div style={pdfStyle.mainPics}>
                <div
                  style={{
                    backgroundImage: getUrlFromArray(images),
                    ...pdfStyle.productPicStyle,
                    ...pdfStyle.mainProductPic,
                    ...pdfStyle.logoProps
                  }} />
              </div>
              <div style={pdfStyle.sidePics}>
                {images && !!images.length && images.map(({ formats = {} }, index) => {
                  const url = getUrlFromObject(formats)
                  return (
                    (url && index > 0 && index < 3) &&
                    <div
                      key={`${index}`}
                      style={{
                        backgroundImage: url,
                        ...pdfStyle.productPicStyle,
                        ...pdfStyle.sideProductPic,
                        ...pdfStyle.logoProps
                      }}
                    />)
                })}
              </div>
            </div>
          </div>
          <div style={pdfStyle.product}>
            <div style={pdfStyle.textColumn}>
              <div style={pdfStyle.titleBox}>
                <div style={pdfStyle.title}>
                  {formattedMessage(name)}
                </div>
              </div>
              <div style={pdfStyle.artNr}>
                {artno}
              </div>
              <div style={pdfStyle.water}>
                {!dont_show && !!types.length && types.map((type, index) => {
                  const typeName = normalizeName(type)
                  return (
                    <div
                      key={`${type}_${index}`}
                      style={{
                        ...pdfStyle.waterLogo,
                        ...pdfStyle.logoProps,
                        backgroundImage: getLogoUrl(typeName)
                      }}
                    />
                  )
                })}
              </div>
              <div style={pdfStyle.priceBox}>
                <b style={pdfStyle.price} className='small-bold'>
                  DKK {price}.-
                </b>
                <b style={pdfStyle.price} className='small-bold'>
                  EURO {price * eurChange}.-
                </b>
                <b style={pdfStyle.priceLast} className='small-bold'>
                  USD {price * usdChange}.-
                </b>
              </div>
              <div style={pdfStyle.infoTable}>
                {!!data.length && data.map(({ name, value }, index) => {
                  const isEven = index % 2 === 0
                  const addedStyle = isEven ? pdfStyle.rowEven : {}
                  const rowStyle = { ...pdfStyle.row, ...addedStyle }
                  return (
                    <div key={`${name}_${value}`} style={rowStyle}>
                      <div style={pdfStyle.rowName}>{name.toUpperCase()}</div>
                      <div style={pdfStyle.rowValue}>{value.toUpperCase()}</div>
                    </div>
                  )
                })}
              </div>
              <div style={pdfStyle.bulletsList}>
                {!!bullet.length && bullet.map(({ value }, index) => (
                  <div key={index}>
                    {`• ${value}`}
                  </div>
                ))}
              </div>
            </div>
            <div style={pdfStyle.technicalColumn}>
              {!!technical_data.length && technical_data.map(({ name, value }, index) => {
                const isEven = index % 2 === 0
                const addedStyle = isEven ? pdfStyle.rowEven : {}
                const rowStyle = { ...pdfStyle.row, ...addedStyle }
                return (
                  <div key={`${name}_${value}`} style={rowStyle}>
                    <div style={pdfStyle.rowName}>{name.toUpperCase()}</div>
                    <div style={pdfStyle.rowValue}>{value.toUpperCase()}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div style={pdfStyle.footerBox}>
            <div style={pdfStyle.footer}>
              {footer}
            </div>
          </div>
        </div>
        <div className='send-button-container'>
          {isPortrait ?
            <>
              <FlatButton
                name='send'
                className='flat-button send'
                handleClick={() => setDialogOpen(true)}
              />
              <FlatButton
                name='close'
                className='flat-button close'
                handleClick={closePdf}
              />
            </> :
            <FlatButton
              name='send'
              className='flat-button send'
              handleClick={() => setDialogOpen(true)}
            />
          }
        </div>
      </div>
      {dialogOpen && <PdfSenderDialog closeDialog={() => setDialogOpen(false)} />}
    </div>
  )
}
