import React from 'react';
import { Grid } from '@material-ui/core';
import { LogoFlatButton } from '../Buttons';

export const LogoFlatButtonArray = ({ array, handleClick = () => { } }) => {
  return (
    <Grid
      container
      className='flat-button-array-container'
    >
      {array && !!array.length && array.map((name, index) => (
        <LogoFlatButton
          key={`${index}_${name}`}
          name={name}
          handleClick={() => handleClick(name)}
        />
      ))}
    </Grid>
  )
}
