import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ZegoContext } from '../../../storage/context';
import { FlatButtonArray } from '../../Common/ButtonArrays';
import { PositionedTextBox } from '../../Common/TextBoxes';
import { getUrlFromArray } from '../../../utils/imageHandler';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const RangePage = () => {
  const [{ storage: { ranges = [] } }] = useContext(ZegoContext)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const { rangeId } = useParams()
  const { url } = useRouteMatch()
  const history = useHistory()
  const actualRange = ranges.find(({ name }) => name === rangeId) || {}
  const { categories: actualCategories = {}, background = [] } = actualRange
  const bgUrl = getUrlFromArray(background, true)

  const handleMenuClick = ({ name = '' }) => {
    history.push(`${url}/${name}`)
  }

  return (
    <div
      style={{ backgroundImage: bgUrl }}
      className={`category-page bg-props${makePortraitClass(isPortrait)}`}
    >
      <PositionedTextBox textData={actualRange} type='main' />
      <FlatButtonArray menus={actualCategories} small handleClick={handleMenuClick} />
    </div>
  )
}