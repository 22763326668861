import { get, set, clear } from 'idb-keyval';

export const getStore = async (key) => {
  return get(key)
    .catch(error => { throw new Error(error) })
}

export const setStoreItem = async (key, value) => {
  set(key, value)
    .catch(error => { throw new Error(error) })
}

export const clearStore = () => {
  clear()
}
