import axios from 'axios'
import { NO_SERVER } from './constants';

const handleErrorSet = (error = {}, setError = () => { }) => {
  const text = (error && error.response && error.response.statusText) || NO_SERVER
  const code = (error && error.response && error.response.status) || 400
  return setError({ text, code })
}

const fetchEndpoint = async (path, setErrorStatus) => {
  const url = process.env.REACT_APP_DATA_URL
  try {
    const response = await axios.get(`${url}/${path}?_limit=-1`, {
      headers: {
        accept: 'application/json'
      }
    })
    const { data = {}, status = 0 } = response || {}
    if (data && status === 200) {
      return response.data
    } else {
      const [messages = {}] = (data && data.message) || []
      const { messages: [errorObject] } = messages
      const errorMessage = (errorObject && errorObject.message) || ''
      setErrorStatus({ text: errorMessage, code: 400 })//TODO get exact code
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus)
  }
}

export { fetchEndpoint }
