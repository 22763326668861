import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid } from '@material-ui/core';
import { FlatButton } from '../Buttons';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';
import { makeSmallClass } from '../../../utils/styleHelpers';

export const FlatButtonArray = ({ menus = [], small = false, handleClick = () => { } }) => {
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  return (
    <Grid
      container
      direction='column'
      className={`button-array-container${makePortraitClass(isPortrait)}`}
    >
      {menus && !!menus.length && menus.map((menu = {}, index) => {
        const { name = menu } = menu
        return (
          <FlatButton
            name={name}
            key={`${name}_${index}`}
            className={`menu${makeSmallClass(small)}`}
            handleClick={handleClick} />
        )
      })}
    </Grid>
  )
}
