import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { Grid } from '@material-ui/core';
import { createEmptyClass, getUrlFromArray } from '../../../utils/imageHandler';

export const TeamCard = ({ item = {} }) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const { name = '', title = '', division = '', phone = '', email = '', address = {}, image = [] } = item
  const { company = '', street = '', city = '' } = address

  const imageUrl = getUrlFromArray(image)
  const handleCardClick = () => setIsFlipped(prevState => !prevState)
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <Grid
        item
        className='card-item team pointer frontside'
        onClick={handleCardClick}
      >
        <div
          className={`card-image logo-props ${createEmptyClass(imageUrl)}`}
          style={{ backgroundImage: imageUrl }}
        />
        <div className='card-text-box'>
          <b className='small-bold'>
            {name.toUpperCase()}
          </b>
          <div>
            {title.toUpperCase()}
          </div>
          <div>
            {division.toUpperCase()}
          </div>
        </div>
      </Grid>
      <Grid
        item
        className='card-item team pointer backside'
        onClick={handleCardClick}
      >
        <div className='card-text-box'>
          <b className='small-bold'>
            {name.toUpperCase()}
          </b>
          <div>
            {title.toUpperCase()}
          </div>
          <div>
            {division.toUpperCase()}
          </div>
          <div className='divider' />
          <div>
            {phone.toUpperCase()}
          </div>
          <div>
            {email.toUpperCase()}
          </div>
          <div className='divider' />
          <div>
            {company.toUpperCase()}
          </div>
          <div>
            {street.toUpperCase()}
          </div>
          <div>
            {city.toUpperCase()}
          </div>
        </div>
      </Grid>
    </ReactCardFlip>
  )
}

