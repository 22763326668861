export const TOKEN = 'token'
export const LANG = 'language'
export const STORAGE = 'storage'
export const NO_SERVER = 'Could not reach server'

export const INTL = 'intl'

export const BACK = '<<'

export const ABOUT_MENUS = ['buy2keep', 'team', 'icons']

export const SUPPORTED_VIDEO_FORMATS = ['mp4', 'ogg', 'mov', 'mkv', 'qt']
export const SUPPORTED_IMAGE_FORMATS = ['jpg', 'jpeg', 'png', 'svg']

export const TAB_ARRAY = ['product', 'tecnical']
export const WORKTOP_SHOW_ARGUMENTS = ['DVT CABINET', 'INTEGRATED']
export const RANGES_NOT_TO_SHOW = ['news', 'co2']
export const DEFAULT_SELECT = '- SELECT -'
export const CHANGE = '- CHANGE -'

export const WATER = 'water'
export const CONTROL = 'controls'
export const WORKTOP = 'worktop'

export const ENDPOINTS = {
  PRODUCTS: 'products',
  RANGES: 'ranges',
  CATEGORIES: 'categories',
  MENUS: 'menus',
  TEAMS: 'teams',
  ICONS: 'icons',
  CURRENCIES: 'currencies',
  GALLERIES: 'galleries',
  MAINMENUS: 'mainmenus',
  ATTRIBUTES: 'product-attributes',
  PDF: 'pdf-exports'
}

export const LANGUAGES = ['dk', 'en']

export const MENU = 'menu'
export const PATHS = {
  root: '/',
  menu: `/${MENU}`,
  product: '/product',
  about: '/about',
  gallery: '/gallery',
  solution: '/solution',
  parameters: {
    rangeId: '/:rangeId',
    categoryId: '/:categoryId',
    productId: '/:productId',
    pageId: '/:pageId'
  }
}
