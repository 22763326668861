import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid } from '@material-ui/core';
import { FlatButton } from '../Buttons';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';
import { makeSmallClass } from '../../../utils/styleHelpers';

export const SelectableFlatButtonArray = ({
  menus = [],
  selected = 0,
  small = false,
  color = 'white',
  handleClick = () => { }
}) => {
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  return (
    <Grid
      container
      direction='column'
      className={`button-array-container${makePortraitClass(isPortrait)}`}
    >
      {menus && !!menus.length && menus.map((menu = {}, index) => {
        const { name = menu } = menu
        const isSelected = selected === index
        const style = isSelected ? { backgroundColor: color, color: 'black' } : {}
        return (
          <FlatButton
            name={name}
            key={`${name}_${index}`}
            style={style}
            className={`menu${makeSmallClass(small)}`}
            handleClick={handleClick} />
        )
      })}
    </Grid>
  )
}
