export const generateMatrix = (arr = []) => {
  const productsArray =
    arr && !!arr.length && arr.map(({ products = [], use = 0 }) => products[use])
  return (Array.isArray(productsArray) && !!productsArray.length &&
    productsArray.reduce((rows, key, index) => (index % 3 === 0
      ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows, []))
    || []
}

export const pdfStyle = {
  headerBox: {
    fontSize: '2.5mm',
    width: '100%',
    marginBottom: '15mm',
    top: '5mm',
    position: 'relative'
  },
  header: {
    textAlign: 'center'
  },
  footerBox: {
    fontSize: '2.5mm',
    position: 'absolute',
    bottom: '5mm',
    width: '100%'
  },
  footer: {
    textAlign: 'center'
  },
  logo: {
    height: '10mm',
    width: '47mm',
    top: '2.5mm',
    right: '2.5mm',
    position: 'absolute',
    backgroundSize: 'cover'
  },
  divider: {
    width: '95%',
    borderBottom: '0.3mm solid black',
    margin: 'auto'
  },
  page: {
    width: '210mm',
    height: '297mm',
    position: 'relative',
    backgroundColor: 'white',
    fontSize: '2.5mm',
    color: 'black',
    zIndex: 10
  },
  product: {
    display: 'flex'
  },
  picContainer: {
    display: 'flex'
  },
  mainPics: {
    width: '75%',
    margin: '11mm 5mm 11mm 13mm'
  },
  sidePics: {
    width: '25%',
    margin: '11mm 13mm 11mm 5mm'
  },
  textColumn: {
    width: '50%',
    margin: '11mm 5mm 11mm 13mm'
  },
  picColumn: {
    width: '50%',
    margin: '11mm 13mm 11mm 5mm'
  },
  technicalColumn: {
    width: '50%',
    margin: '49mm 13mm 11mm 5mm'
  },
  titleBox: {
    marginBottom: '1.2mm'
  },
  title: {
    fontSize: '10mm'
  },
  artNr: {
    marginBottom: '1.2mm'
  },
  water: {
    display: 'flex',
    margin: '2.5mm 0',
    height: '7.5mm'
  },
  waterLogo: {
    width: '7.5mm',
    height: '7.5mm',
    marginRight: '1.2mm'
  },
  priceBox: {
    display: 'flex',
    fontSize: '2.5mm'
  },
  price: {
    backgroundColor: '#e9e9e9',
    marginRight: '2.5mm',
    width: '33%',
    padding: '2mm 1.2mm'
  },
  priceLast: {
    backgroundColor: '#e9e9e9',
    width: '33%',
    padding: '2mm 1.2mm'
  },
  infoTable: {
    marginTop: '2.5mm'
  },
  row: {
    padding: '1mm 1.2mm',
    display: 'flex'
  },
  rowEven: {
    backgroundColor: '#ededed'
  },
  rowName: {
    textAlign: 'left',
    width: '50%'
  },
  rowValue: {
    textAlign: 'right',
    width: '50%'
  },
  bulletsList: {
    marginTop: '2.5mm',
  },
  picStyle: {
    height: '48mm',
    backgroundSize: 'contain',
    transform: 'translateY(20%)'
  },
  productPicStyle: {
    backgroundSize: 'contain',
    border: '0.3mm black solid'
  },
  mainProductPic: {
    height: '100mm'
  },
  sideProductPic: {
    height: '48mm'
  },
  logoProps: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
}
