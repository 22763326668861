import React, { useContext } from 'react';
import { ZegoContext } from '../../../storage/context';
import { CardListArray } from '../../Common/CardArrays';
import { PositionedTextBox } from '../../Common/TextBoxes';
import { getUrlFromArray } from '../../../utils/imageHandler';

export const TeamPage = ({ menuObject = {}, menuButtons }) => {
  const [{ storage: { teams = [] }, mediaView = false }, dispatch] = useContext(ZegoContext)
  const { bgArray, menuData } = menuObject

  const handleAllClick = () => {
    dispatch({
      type: 'setView',
      mediaView: true
    })
  }

  return (
    <div className="team-page bg-props" style={{ backgroundImage: getUrlFromArray(bgArray, true) }}>
      {!mediaView ?
        <>
          <PositionedTextBox textData={menuData} type='about' buttonName='view all' handleClick={handleAllClick} />
          {menuButtons}
          <CardListArray listArray={teams} team />
        </> :
        <CardListArray listArray={teams} team all />
      }
    </div>
  )
}
