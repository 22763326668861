import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Fade } from 'react-reveal';
import { Grid, /*Slide*/ } from '@material-ui/core';
import Slider from 'react-slick';
import { ProductCard, TeamCard } from '../Cards';
import { makeAllSettings, makeMainSettings } from '../../../utils/sliderHelpers';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';
import { makeAllClass } from '../../../utils/styleHelpers';

export const CardListArray = ({
  listArray = [],
  color = '#1c1c1c',
  all = false,
  team = false,
  handleClick = () => { }
}) => {
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const settings = all ?
    makeAllSettings(listArray, isPortrait) :
    makeMainSettings(listArray, isPortrait)

  return (
    <Grid
      container
      className={`card-list-array-container${makeAllClass(all)}${makePortraitClass(isPortrait)}`}
    >
      <Slider {...settings} >
        {listArray && !!listArray.length && listArray.map((item, index) => (
          <Fade
            right
            delay={index * 200}
            key={index}
          >
            {/*<Slide
            in={true}
            direction="left"
            timeout={(index + 1) * 200}
            key={index}
          >*/}
            <div>
              {team ?
                <TeamCard item={item} color={color} /> :
                <ProductCard item={item} color={color} handleClick={handleClick} />
              }
            </div>
            {/*</Slide>*/}
          </Fade>
        ))}
      </Slider>
    </Grid>
  )
}
