import React from 'react';

export const SearchField = ({
  placeholder = '',
  value = '',
  className = '',
  long = false,
  handleChange = () => { },
  handleKeyDown = () => { }
}) => {
  const inputPh = placeholder ? placeholder : '\uf002'
  return (
    <div className='text-input-container'>
      {long ?
        <textarea
          className={`text-input ${className}`}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        /> :
        <input
          className={`text-input ${className}`}
          value={value}
          placeholder={inputPh}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      }
    </div>
  )
}
