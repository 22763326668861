import { getUrlFromArray } from "./imageHandler";

export const getMenuData = (menus = [], menuName = '') => {
  const menuData = menus.find(({ name = '' }) => name.indexOf(menuName) > -1) || {}
  const { background = [] } = menuData
  const bgUrl = getUrlFromArray(background, true)
  return {
    bgUrl,
    bgArray: background,
    menuData
  }
}
