import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { SearchField } from '../Fields';
import { formattedMessage } from '../../../utils/textHandlerUtils';
import { filterTranslation } from '../../../utils/translationUtils';
import { makeDescStyle, makePortraitClass, makeTitleStyle, positionSelector } from '../../../utils/mobileViewHelpers';

export const SearchTextBox = ({
  textData = {},
  type = '',
  textColor = 'black',
  value = '',
  handleChange = () => { },
  handleKeyDown = () => { }
}) => {
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const {
    header = {},
    description = {},
    text_position = 'middle',
    header_line_height = 2,
    header_font_size = 40,
    desc_line_height = 1,
    desc_font_size = 20
  } = textData

  const titleStyle = makeTitleStyle(header_line_height, header_font_size, isPortrait)
  const descStyle = makeDescStyle(desc_line_height, desc_font_size, isPortrait)

  const position = isPortrait ? { top: '5vh' } : positionSelector[text_position]

  return (
    <div
      style={{ ...position, color: textColor }}
      className={`positioned-text-box ${type}${makePortraitClass(isPortrait)}`}
    >
      {header && formattedMessage(filterTranslation(header), 'title', titleStyle)}
      {description && formattedMessage(filterTranslation(description), 'description', descStyle)}
      <SearchField
        value={value}
        className='search'
        handleChange={event => handleChange(event)}
        handleKeyDown={event => handleKeyDown(event)}
      />
    </div>
  )
}
