import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { ZegoContext } from '../../../storage/context';
import home from '../../../assets/images/logos/home.svg';
import refresh from '../../../assets/images/logos/refresh.svg';
import { PATHS } from '../../../utils/constants';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const HomeButtons = ({ showRefresh = false }) => {
  const [{ getData = false, mediaView = false }, dispatch] = useContext(ZegoContext)
  const [refreshing, setRefreshing] = useState(false)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const history = useHistory()
  const { pathname } = useLocation()
  const { root } = PATHS

  useEffect(() => {
    const hjid = 2864963
    const hjsv = 6
    hotjar.initialize(hjid, hjsv);

    // Identify the user
    hotjar.identify('USER_ID', { userProperty: 'value' });

    // Add an event
    hotjar.event('button-click');

    // Update SPA state
    hotjar.stateChange("/");
  }, [])

  useEffect(() => {
    hotjar.stateChange(pathname);
  }, [pathname])

  const navigateHome = () => {
    if (mediaView) {
      dispatch({
        type: 'setView',
        mediaView: false
      })
    }
    history.push('/menu')
  }

  const handleRefresh = async () => {
    if (!refreshing && !getData) {
      setRefreshing(true)
      dispatch({
        type: 'setGetData',
        getData: true
      })
    }
    setRefreshing(false)
  }

  return (
    <>
      {pathname !== root &&
        (<img
          alt='logo'
          src={home}
          className={`home-button logo-prop pointer${makePortraitClass(isPortrait)}`}
          onClick={navigateHome}
        />)}
      {showRefresh && <img
        alt='refresh'
        src={refresh}
        onClick={handleRefresh}
        className={`refresh-button logo-prop pointer${refreshing ? ' refreshing' : ''}`}
      />}
    </>
  )
}
