import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router-dom';
import { ZegoContext } from '../../../storage/context';
import { KeepPage, TeamPage, IconPage } from './';
import { SelectableFlatButtonArray } from '../../Common/ButtonArrays';
import { getMenuData } from '../../../utils/menuHelpers';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';
import { ABOUT_MENUS } from '../../../utils/constants';

export const AboutPages = () => {
  const [{ storage: { menus = [] } }] = useContext(ZegoContext)
  const { pageId } = useParams()
  const history = useHistory()
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const menuObject = getMenuData(menus, pageId)

  const selected = ABOUT_MENUS.indexOf(pageId)

  const handleMenuClick = ({ name = '' }) => {
    history.push(name)
  }

  const menuButtons = (
    <SelectableFlatButtonArray menus={ABOUT_MENUS} selected={selected} small handleClick={handleMenuClick} />
  )
  const pageSelector = {
    [ABOUT_MENUS[0]]: <KeepPage menuObject={menuObject} menuButtons={menuButtons} />,
    [ABOUT_MENUS[1]]: <TeamPage menuObject={menuObject} menuButtons={menuButtons} />,
    [ABOUT_MENUS[2]]: <IconPage menuObject={menuObject} menuButtons={menuButtons} />
  }
  return (
    <div className={`about-page sub bg-props${makePortraitClass(isPortrait)}`}>
      {pageSelector[pageId]}
    </div>
  )
}