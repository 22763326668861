import { useContext, useEffect } from 'react';
import { ZegoContext } from './context';
import { fetchEndpoint } from '../utils/fetchUtils';
import { setStoreItem, getStore } from '../utils/storageUtils';
import { ENDPOINTS, STORAGE } from '../utils/constants';

const StorageHandler = () => {
  const [{ storage: contextStorage = {}, error = {}, getData = false }, dispatch] = useContext(ZegoContext)

  useEffect(() => {
    const handlingStorage = async () => {
      const storage = await getStore(STORAGE) || {}
      if (getData) {
        dispatch({ type: 'setGetData', getData: false })
        const initStorage = async () => {
          try {
            const endpoints = Object.values(ENDPOINTS)
            for (let i = 0; i < endpoints.length; i++) {
              const name = endpoints[i]
              storage[name] = await fetchEndpoint(name, error => dispatch({ type: 'setError', error }))
            }
            await setStoreItem(STORAGE, storage)
          } catch (error) {
            throw new Error(error)
          }
          dispatch({
            type: 'setStorage',
            storage
          })
        }
        await initStorage()
      } else if (!contextStorage.products && !!Object.keys(storage).length) {
        dispatch({
          type: 'setStorage',
          storage
        })
      }
    }
    handlingStorage()
  }, [error, getData, contextStorage, dispatch])
  return null
}

export default StorageHandler
