import React from 'react';
import Slider from 'react-slick';
import VideoPlayer from '../Media/VideoPlayer';
import { getLink, isFileVideo, isFileSupported } from '../../../utils/fileHandlerUtils';
import { makeAlbumSettings } from '../../../utils/sliderHelpers';
import { getUrlFromArray } from '../../../utils/imageHandler';

export const AlbumCard = ({ product = {}, pageType = 0 }) => {
  const { images = [], technical_images = [], logo = [] } = product
  const isMainAlbum = pageType === 0

  const logoUrl = getUrlFromArray(logo) || ''

  const settings = makeAlbumSettings()

  const supportedFiles = (
    isMainAlbum ?
      images :
      technical_images
  ).filter(({ url }) => isFileSupported(url)) || []

  const supportedLinks = supportedFiles.map(({ url, formats }) => {
    return formats && formats.medium && formats.medium.url ?
      formats.medium.url :
      url
  }) || []

  return (
    <div className='album-card'>
      {(logoUrl && isMainAlbum) && <div
        style={{ backgroundImage: logoUrl }}
        className='brand-logo logo-props'
      />}
      <Slider {...settings}>
        {supportedLinks && supportedLinks.map((file, index) => {
          const url = file ? getLink(file) : ''
          return (
            isFileVideo(file) ? <VideoPlayer key={`${file}_${index}`} src={url} />
              :
              (<div key={`${file}_${index}`} >
                <div
                  style={{ backgroundImage: url }}
                  className='image-album logo-props pointer'
                />
              </div>
              ))
        })}
      </Slider>
    </div>
  )
}