import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getUrlFromArray } from '../../../utils/imageHandler';
import { formattedMessage } from '../../../utils/textHandlerUtils';
import { LANG } from '../../../utils/constants';

export const MenuCard = ({ item, index }) => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const lang = localStorage.getItem(LANG)
  const { name = '', header = {}, description = {}, image = [] } = item
  const title = header[lang] || ''
  const desc = description[lang] || ''

  const isEven = index % 2 === 0
  const imageClass = isEven ? 'right' : 'left'
  const textClass = isEven ? 'left' : 'right'
  return (
    <Grid
      item
      className='menu-card pointer'
      onClick={() => history.push(`${url}/${name}`)}
    >
      <div
        className={`menu-card-image logo-props ${imageClass}`}
        style={{ backgroundImage: getUrlFromArray(image) }}
      />
      <div className={`menu-card-text-container ${textClass}`}>
        <div className={`menu-card-text-box ${textClass}`}>
          {formattedMessage(title.toUpperCase(), 'title')}
          {formattedMessage(desc, 'description')}
        </div>
      </div>
    </Grid>
  )
}
