export const getSimplifiedName = (name = '') => {
  return name
    .replaceAll(/\/|,|\.|%|\s+/g, '')
    .toLowerCase() || ''
}

export const getSimplifiedEndpoint = (name = '') => {
  const [SimplifiedEndpoint] = name.split('-')
  return SimplifiedEndpoint
}
