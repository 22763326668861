import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { TOKEN } from '../../../utils/constants';

export const ProtectedRoute = ({ path, component }) => {
  const token = localStorage.getItem(TOKEN) || 'token'
  return (
    token ?
      <Route path={path} component={component} /> :
      <Redirect to='/' />
  )
}
