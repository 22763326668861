import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { makeImageUrl } from '../../../utils/imageHandler';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';
import { makeAlbumSettings } from '../../../utils/sliderHelpers';

export const BackgroundArray = ({ backgroundArray = [], text = false }) => {
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const settings = makeAlbumSettings()

  return (
    <div className={`background-array${makePortraitClass(isPortrait)}`}>
      <Slider {...settings} >
        {!!backgroundArray.length && backgroundArray.map(({ url, description }, index) => (
          <div key={index}>
            <div
              style={{ backgroundImage: makeImageUrl(url) }}
              className='bg-props'
            />
            {text &&
              <div className='text-container'>
                <div className='text'>
                  {description}
                </div>
              </div>
            }
          </div>
        )
        )}
      </Slider >
    </div>
  )
}