import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ZegoContext } from '../../../storage/context';
import { SelectableFlatButtonArray, TabButtonArray } from '../../Common/ButtonArrays';
import { ProductDetailsCard, TechnicalCard, AlbumCard } from '../../Common/Cards';
import { FlatButton } from '../../Common/Buttons';
import { getSimplifiedName } from '../../../utils/routerHelperUtils';
import { TAB_ARRAY } from '../../../utils/constants';
import { ProductPdfPage } from '../../Common/Pdf/ProductPdfPage';
import { getCurrencies } from '../../../utils/productHelpers';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const ProductPage = () => {
  const [{ storage: {
    ranges = [],
    categories = [],
    products = [],
    currencies = [],
    'pdf-exports': pdfData = []
  } }] = useContext(ZegoContext)
  const [actualPage, setActualPage] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [showPdf, setShowPdf] = useState(false)
  const { rangeId, categoryId, productId } = useParams()
  const history = useHistory()
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const actualRange = ranges.find(({ name }) => name === rangeId) || {}
  const { categories: actualCategories = {} } = actualRange

  useEffect(() => {
    const actualCategoryIndex = categories.findIndex(({ name }) => name === categoryId) || 0
    setSelectedCategory(actualCategoryIndex)
  }, [categories, categoryId])

  const color = 'gray'

  const actualProduct = products.find(({ id }) => id === productId) || {}

  const currencyObject = getCurrencies(currencies)

  const componentSelector = (page = 0) => {
    const components = {
      0: <ProductDetailsCard product={actualProduct} currencies={currencyObject} />,
      1: <TechnicalCard product={actualProduct} />
    }
    return components[page]
  }

  const handlePageSet = ({ index = 0 }) => setActualPage(index)

  const handleMenuClick = ({ name = '' }) => {
    history.push(`../${getSimplifiedName(name)}`)
  }

  const handlePdfDownload = () => {
    setShowPdf(true)
  }

  return (
    <div className={`product-page${makePortraitClass(isPortrait)}`}>
      {showPdf &&
        <ProductPdfPage
          toPrint={actualProduct}
          pdfData={pdfData[0] || {}}
          currencies={currencyObject}
          closePdf={() => setShowPdf(false)}
        />}
      {isPortrait ?
        <div className='content'>
          <AlbumCard product={actualProduct} pageType={actualPage} />
          <div className='scrollbar-box-portrait'>
            {componentSelector(actualPage)}
          </div>
          <div className='download-button-container'>
            <FlatButton name='download data sheet' className='download-button' handleClick={handlePdfDownload} />
          </div>
          <SelectableFlatButtonArray
            menus={actualCategories}
            selectedColor={color}
            selected={selectedCategory}
            small
            handleClick={handleMenuClick}
          />
        </div>
        : <Grid
          container
          direction='row'
          className='page-content'>
          <Grid xs={5} item>
            <div className='scrollbar-box'>
              {componentSelector(actualPage)}
            </div>
            <FlatButton name='download data sheet' className='download-button' handleClick={handlePdfDownload} />
          </Grid>
          <Grid xs={5} className='album' item>
            <AlbumCard product={actualProduct} pageType={actualPage} />
          </Grid>
          <Grid xs={2} item>
            <div />
          </Grid>
        </Grid>}
      <TabButtonArray array={TAB_ARRAY} page={actualPage} handleClick={handlePageSet} color={color} />
      <div className='footer-box' style={{ backgroundColor: color }} />
      {!isPortrait &&
        <SelectableFlatButtonArray
          menus={actualCategories}
          selectedColor={color}
          selected={selectedCategory}
          small
          handleClick={handleMenuClick}
        />
      }
    </div>
  )
}