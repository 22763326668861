import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid } from '@material-ui/core';
import { MenuCard } from '../Cards';
import { makePortraitClass } from '../../../utils/mobileViewHelpers';

export const MenuCardArray = ({ menus = [] }) => {
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  return (
    <Grid
      container
      direction='column'
      className={`menu-card-array-container${makePortraitClass(isPortrait)}`}
    >
      {menus && !!menus.length && menus.map((item, index) => (
        <MenuCard item={item} index={index} key={`${item}_${index}`} />
      ))}
    </Grid>
  )
}