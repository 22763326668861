import React from 'react';
import { Grid } from '@material-ui/core';
import { formattedMessage } from '../../../utils/textHandlerUtils';

export const TabButtonArray = ({ array = [], color = '', page = 0, handleClick = () => { } }) => (
  <Grid
    container
    className='tab-button-array-container'
  >
    {array && !!array.length && array.map((name, index) => {
      const selected = index === page
      const className = selected ? '' : ' not-selected'
      return (
        <button
          key={`${name}_${index}`}
          className={`tab-button pointer${className}`}
          onClick={() => handleClick({ name, index })}
          style={{
            opacity: selected ? 1 : 0.5,
            backgroundColor: color
          }}
        >
          {formattedMessage(name)}
        </button>
      )
    })}
  </Grid>
)
